.EmailConfirmationComplete__Text {
  margin: 24px 0 0;
  text-align: center;
  font-size: 1.4rem;
  font-weight: bold;
}
.EmailConfirmationComplete__Text span{
  display: block;
}
.EmailConfirmationComplete__LinkContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin: 16px 0 0;
}
.EmailConfirmationComplete__LinkApple {
  flex: 0 1 146px;
}
.EmailConfirmationComplete__LinkAndroid {
  flex: 0 1 180px;
}
.EmailConfirmationComplete__Image {
  width: 100%;
  height: auto;
  vertical-align: bottom;
}
.EmailConfirmationComplete__List {
  margin: 16px 0 0;
  padding: 0 12px;
  list-style-type: none;
}
.EmailConfirmationComplete__ListItem {
  padding-left: 16px;
  position: relative;
  margin: 8px 0 0;
  font-size: 1.2rem;
}
.EmailConfirmationComplete__ListItem:before {
  content: "※";
  position: absolute;
  top: 0;
  left: 0;
  font-size: 1.2rem;
}