.EmailConfirmationInput__Text {
  margin: 8px 0 32px;
  text-align: center;
  font-size: 1.4rem;
  opacity: .6;
  font-weight: bold;
}
.EmailConfirmationInput__FormWrap {
  margin: 12px 0 0;
}
.EmailConfirmationInput__Label {
  display: block;
  font-size: 1.4rem;
  font-weight: 700;
  margin-bottom: 6px;
}
.EmailConfirmationInput__FormBody{
  position: relative;
}
.EmailConfirmationInput__ToggleIcon{
  width: 40px;
  height: 40px;
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: .2;
  cursor: pointer;
  transition: .2s;
}
.EmailConfirmationInput__ToggleIcon:hover{
  opacity: .3;
}
.EmailConfirmationInput__ToggleIcon img{
  width: 24px;
}
.EmailConfirmationInput__Button {
  width: 100%;
  margin: 32px 0 0;
  padding: 12px;
  font-size: 1.6rem;
  font-weight: bold;
  border: none;
  border-radius: 9px;
  background: var(--primary);
  color: var(--white);
  transition: opacity 0.2s;
}
.EmailConfirmationInput__Button:hover {
  opacity: 0.8;
}
.EmailConfirmationInput__Button:disabled {
  background: var(--icon-inactive-and-bg-gray-dark);
  opacity: 1;
}
.EmailConfirmationInput__Button:disabled.--is-process {
  background: var(--primary);
  opacity: 1;
  cursor: not-allowed;
}

/* animation */
.ball-pulse > div:nth-child(1) {
  -webkit-animation: scale 0.75s -0.24s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
          animation: scale 0.75s -0.24s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
}
.ball-pulse > div:nth-child(2) {
  -webkit-animation: scale 0.75s -0.12s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
          animation: scale 0.75s -0.12s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
}
.ball-pulse > div:nth-child(3) {
  -webkit-animation: scale 0.75s 0s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
          animation: scale 0.75s 0s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
}
.ball-pulse > div {
  background-color: #fff;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  margin: 2px;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  display: inline-block;
}
@-webkit-keyframes scale {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
  45% {
    -webkit-transform: scale(0.1);
            transform: scale(0.1);
    opacity: 0.7;
  }
  80% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}
@keyframes scale {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
  45% {
    -webkit-transform: scale(0.1);
            transform: scale(0.1);
    opacity: 0.7;
  }
  80% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}