/* reset css */
/* ------------------------- */

html {
    color: #000;
    background: #fff;
}

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
textarea,
p,
blockquote,
th,
td {
    margin: 0;
    padding: 0;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
}

fieldset,
img {
    border: 0;
}

address,
caption,
cite,
code,
dfn,
em,
strong,
th,
var {
    font-style: normal;
    font-weight: normal;
}

ol,
ul {
    list-style: none;
}

caption,
th {
    text-align: left;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: 100%;
    font-weight: normal;
}

q:before,
q:after {
    content: "";
}

abbr,
acronym {
    border: 0;
    font-variant: normal;
}

sup {
    vertical-align: text-top;
}

sub {
    vertical-align: text-bottom;
}

input,
textarea,
select,
button {
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    *font-size: 100%;
    border-radius: 0;
    border: none;
    appearance: none;
    -webkit-appearance: none;
    background-color: inherit;
}

input,
textarea,
select {
    font-size: 16px;
}

textarea {
    resize: vertical;
    display: block;
}

button {
    padding: 0;
    cursor: pointer;
}

legend {
    color: #000;
}

main {
    display: block;
}

a {
    text-decoration: none;
    color: inherit;
}

img {
    width: 100%;
    height: auto;
    vertical-align: bottom;
}

svg {
    display: block;
}

* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
}

*::before,
*::after {
    box-sizing: border-box;
}

html,
body {
    background-color: var(--bg-primary-extra-light);
}

html {
    font-size: 62.5%;
}

body {
    font-family: "Noto Sans JP", sans-serif;
    color: #5b5b5b;
    line-height: 1.75;
    letter-spacing: 0.05em;
}

/* component */
/* ------------------------- */

.l-wraper {
    padding: 56px 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media screen and (min-width: 768px) {
    .l-wraper {
        padding: 56px 24px;
    }
}

.l-container {
    max-width: 480px;
    width: 100%;
}

.l-body {
    margin: 24px 0 0;
    padding: 32px 20px;
    background: var(--white);
    box-shadow: 0px 0px 24px rgba(51, 51, 51, 0.03);
    border-radius: 24px;
}

@media screen and (min-width: 768px) {
    .l-body {
        padding: 36px 32px;
    }
}

.c-logo {
    width: 130px;
    margin: 0 auto;
}

.c-heading {
    font-size: 2rem;
    text-align: center;
    font-weight: 700;
}

@media screen and (min-width: 768px) {
    .c-heading {
        font-size: 2.2rem;
    }
}

.c-input {
    width: 100%;
    padding: 12px;
    font-size: 1.4rem;
    border-radius: 8px;
    background: var(--bg-grey-light);
    border: none;
    box-shadow: 0 0 0 1px var(--border) inset, 0 0 0 4px transparent inset;
    appearance: none;
    outline: 0;
    font-size: 16px;
}

.c-input::-webkit-credentials-auto-fill-button {
    margin: 0;
    width: 0;
    background-color: transparent;
}

.c-input:focus {
    box-shadow: 0 0 0 1px var(--outline) inset, 0 0 0 4px var(--outline-inset);
}

.u-visibilityHidden {
    position: absolute;
    white-space: nowrap;
    width: 1px;
    height: 1px;
    overflow: hidden;
    border: 0;
    padding: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    margin: -1px;
}
