:root {
  --primary: #F0361D;
  --accent: #38C24B;
  --bg-primary-light: #FFEBEA;
  --bg-primary-extra-light: #FFF8F8;
  --bg-gray: #F4F4F4;
  --bg-grey-light: #FBFBFB;
  --text-base-and-button: #4D4D4D;
  --icon-inactive-and-bg-gray-dark: #BBBBBB;
  --text-base-and-button: #4D4D4D;
  --text-placeholder: #B9B9B9;
  --text-placeholder02: #949494;
  --text-grey: #A0A0A0;
  --text-link: #318DCF;
  --border: #F1F1F1;
  --outline: #6699df;
  --outline-inset: #87afe74e;
  --white: #FFFFFF;
  --memo: #3A76EB;
}